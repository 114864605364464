import { FC, ReactNode } from 'react'

import styles from './partnerGrid.module.scss'

export interface PartnerGridProps {
  children: ReactNode | ReactNode[]
}

const PartnerGrid: FC<PartnerGridProps> = ({ children }) => {
  return <div className={styles.grid}>{children}</div>
}

export default PartnerGrid
