import {
  apolloClient,
  fetchBanners,
  fetchMarketById,
  fetchPage,
  fetchPageLinks,
  GetPartnersQuery,
  GetPartnersQueryVariables,
  GET_PARTNERS_QUERY,
  localeToMarketCode,
  PageProps,
  Partners_Filter,
  Partners_Translations_Filter,
} from '@instabox/consumer-web-shared'
import { AnchorButton, Heading, List, ListItem, Stack, Text } from '@instabox/consumer-web-ui'
import cn from 'classnames'
import { GetStaticProps, NextPage } from 'next'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { Suspense } from 'react'
import { FormattedMessage } from 'react-intl'

import PageContent from '../components/PageContent'
import PageSection from '../components/PageSection'
import Partner from '../components/Partner'
import PartnerGrid from '../components/PartnerGrid'
import TextWithImage from '../components/TextWithImage'
import styles from './index.module.scss'

const PhoneAnimation = dynamic(() => import('../components/PhoneAnimation'), { ssr: false })

export interface HomePageProps extends PageProps {
  partners: GetPartnersQuery['partners']
}

const SLUG = 'home'

const HomePage: NextPage<HomePageProps> = ({ partners, content, market }) => {
  const textWithImages = content.filter(({ item }) => item.__typename === 'texts_with_images')
  const [mainHero, flagHero] = content.filter(({ item }) => item.__typename === 'heroes')

  return (
    <>
      {mainHero && (
        <PageContent fields={['heroes']} content={[mainHero]}>
          <Stack gap="lg">
            <Heading color="white" align="center" italic>
              <FormattedMessage id="home.title" />
            </Heading>
            <div className="row justify-content-center">
              <div className="col-auto">
                <Link href="/search" passHref legacyBehavior>
                  <AnchorButton size="large" variant="solid">
                    <FormattedMessage id="home.search" />
                  </AnchorButton>
                </Link>
              </div>
              <div className="col-auto">
                <Link href="/help" passHref legacyBehavior>
                  <AnchorButton size="large" variant="solid">
                    <FormattedMessage id="home.help" />
                  </AnchorButton>
                </Link>
              </div>
              {market.code.toLowerCase() == 'se' && (
                <div className="col-auto">
                  <Link href="/help/how-do-i-know-its-safe" passHref legacyBehavior>
                    <AnchorButton size="large" variant="solid">
                      <FormattedMessage id="home.avoid_fraud" />
                    </AnchorButton>
                  </Link>
                </div>
              )}
            </div>
          </Stack>
        </PageContent>
      )}
      <PageSection backgroundColor="var(--color-pale-pink)">
        <div className={cn('row', 'align-items-center', styles.row)}>
          <div className="col-12 col-md-6">
            <Stack gap="lg">
              <Stack gap="sm">
                <Heading color="really-red" fontSize="xl">
                  <FormattedMessage id="home.what_is_instabox.title" />
                </Heading>
                <Text>
                  <FormattedMessage id="home.what_is_instabox.subtitle" />
                </Text>
              </Stack>
              <Stack gap="sm">
                <Heading as="label" color="really-red">
                  <FormattedMessage id="home.what_is_instabox.list.label" />
                </Heading>
                <List variant="secondary">
                  <ListItem count={1} variant="secondary">
                    <FormattedMessage id="home.what_is_instabox.list.item_1" />
                  </ListItem>
                  <ListItem count={2} variant="secondary">
                    <FormattedMessage id="home.what_is_instabox.list.item_2" />
                  </ListItem>
                  <ListItem count={3} variant="secondary">
                    <FormattedMessage id="home.what_is_instabox.list.item_3" />
                  </ListItem>
                </List>
              </Stack>
            </Stack>
          </div>
          <Suspense fallback={`Loading...`}>
            <div className="col-12 col-md-6">
              <PhoneAnimation />
            </div>
          </Suspense>
        </div>
      </PageSection>
      {partners.length > 0 && (
        <PageSection backgroundColor="var(--color-better-blush)">
          <Heading align="center" fontSize="xl" color="really-red">
            <FormattedMessage id="home.partners.subtitle" />
          </Heading>
          <Text align="center" fontWeight="medium">
            <FormattedMessage id="home.partners.title" />
          </Text>
          <PartnerGrid>
            {partners.map(partner => (
              <Partner key={partner.id} {...partner} />
            ))}
          </PartnerGrid>
        </PageSection>
      )}
      {textWithImages.length > 0 && (
        <PageSection gap="xl">
          <Heading align="center" color="white" fontSize="xl">
            <FormattedMessage id="home.why_instabox.header" />
          </Heading>
          <div className="row justify-content-center">
            {textWithImages.map(({ item }) => {
              if (item.__typename === 'texts_with_images') {
                return (
                  <div className="col-12 col-md-6 col-lg-4" key={item.id}>
                    {/* @ts-ignore */}
                    <TextWithImage {...item} layout="card" />
                  </div>
                )
              }
              return null
            })}
          </div>
        </PageSection>
      )}
      {flagHero && (
        <PageContent fields={['heroes']} content={[flagHero]}>
          <Stack>
            <Heading align="center" color="really-red" fontSize="xl">
              <FormattedMessage id="home.find_us.title" />
            </Heading>
            <div className="row justify-content-center">
              <div className="col-auto">
                <Link href="/places" passHref legacyBehavior>
                  <AnchorButton size="large">
                    <FormattedMessage id="home.find_us.button" />
                  </AnchorButton>
                </Link>
              </div>
            </div>
          </Stack>
        </PageContent>
      )}
    </>
  )
}

export default HomePage

export const getStaticProps: GetStaticProps<HomePageProps> = async ({ locale }) => {
  const marketCode = localeToMarketCode(locale)
  const {
    data: { marketById },
  } = await fetchMarketById(localeToMarketCode(locale))
  const page = await fetchPage(locale, SLUG)
  const pageLinks = await fetchPageLinks(locale)
  const filter: Partners_Filter = {
    markets: {
      markets_code: { code: { _eq: marketCode } },
    },
  }
  const translationsFilter: Partners_Translations_Filter = {
    languages_code: {
      _eq: locale,
    },
  }
  const {
    data: { partners },
  } = await apolloClient.query<GetPartnersQuery, GetPartnersQueryVariables>({
    query: GET_PARTNERS_QUERY,
    variables: {
      filter,
      translations_filter: translationsFilter,
    },
  })
  const banners = await fetchBanners(locale, SLUG)
  return {
    props: {
      market: marketById,
      pageLinks,
      partners,
      banners,
      fullBleed: true,
      ...page,
    },
    notFound: !page,
    revalidate: 60,
  }
}
