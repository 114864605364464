import { GetPartnersQuery, getTranslation, Unarray } from '@instabox/consumer-web-shared'
import { Card } from '@instabox/consumer-web-ui'
import Image from 'next/legacy/image'
import { FC } from 'react'

import { ENV_CONFIG } from '../../constants/envConfig'
import styles from './partner.module.scss'

export type PartnerProps = Unarray<GetPartnersQuery['partners']>

const Partner: FC<PartnerProps> = ({ logo, name, url, translations }) => {
  const translatedLogo = translations[0]?.logo || logo
  return (
    <Card className={styles.card} size="sm" variant="transparent">
      <a
        href={getTranslation('url', translations, url)}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.link}>
        <Image
          src={`${ENV_CONFIG.DIRECTUS_URL}/assets/${translatedLogo.id}`}
          alt={name}
          width={translatedLogo.width || 150}
          height={translatedLogo.height || 100}
          objectFit="contain"
        />
      </a>
    </Card>
  )
}

export default Partner
